const checkCookieConsent = (consentGroup) => {
  console.log("checkCookieConsent", window, window?.OnetrustActiveGroups);
  if (typeof window !== "undefined" && window.OnetrustActiveGroups) {
    console.log("UITIL ACTIVECONSENTGROUP", window.OnetrustActiveGroups, {
      consentGroup,
    });

    return window.OnetrustActiveGroups.includes(consentGroup);
  }
  return false;
};

export default checkCookieConsent;
