import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { CacheProvider } from "@emotion/react";
import { config as fa5Config } from "@fortawesome/fontawesome-svg-core";
import { loadableReady } from "@loadable/component";
import { StyleSheet } from "aphrodite";
import { createBrowserHistory } from "history";
import { fromJS } from "immutable";
import mixpanel from "mixpanel-browser";
import pathToRegexp from "path-to-regexp";
import qhistory from "qhistory";
import { parse, stringify } from "qs";
import mapObjIndexed from "ramda/src/mapObjIndexed";
import * as React from "react";
import { hydrate, render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StoreContext } from "redux-react-hook";
import Cookies from "universal-cookie";

import AppContainer from "pages/AppContainer";
import EmbedApp from "pages/Embed/async";
import RequestContext from "pages/RequestContext";

import config from "./config";

import "fix-date";

import authActions from "actions/auth";
import { INIT_LOADED_TIMELINES } from "constants/timeline";
import reportWebVitals from "utils/analytics/reportWebVitals";
import { getStoredToken } from "utils/auth";
import checkCookieConsent from "utils/checkCookieConsent";
import createEmotionCache from "utils/createEmotionCache";
import configureStore from "utils/redux/configureStore";

if ("scrollRestoration" in window.history) {
  // Back off, browser, I got this...
  window.history.scrollRestoration = "manual";
}
Object.assign = null;
Object.assign = require("object-assign");

require("styles/css/flexboxgrid-base.css");
require("styles/css/flexboxgrid-custom.css");
require("styles/css/colors.css");
require("styles/css/base.css");
require("styles/css/colors.css");
require("@fortawesome/fontawesome-svg-core/styles.css");
require("react-day-picker/dist/style.css");
fa5Config.autoAddCss = false;
fa5Config.autoReplaceSvg = false;
fa5Config.observeMutations = false;

const initializeMixpanel = () => {
  if (!window.mixpanel) {
    mixpanel.init("82cc2954c36a93da9e478a070ae6773d", { debug: true });
    window.mixpanel = mixpanel;
  }
};

if (checkCookieConsent("C0002")) {
  initializeMixpanel();
} else {
  window.mixpanel = null;
  console.log("MIXPANEL Disabled");
}

// Listen for changes to consent and re-check consent on update
if (typeof window !== "undefined") {
  window.addEventListener("OneTrustGroupsUpdated", () => {
    if (checkCookieConsent("C0002")) {
      initializeMixpanel();
      console.log("MIXPANEL initialize");
    } else {
      window.mixpanel = null;
      console.log("MIXPANEL Disabled");
    }
  });
}

const cookies = new Cookies();

let requestContext = {
  server: false,
  userAgent: window.navigator.userAgent,
  navigator: window.navigator,
  environment: config("environment"),
  isTestingEnv: config("isTestingEnv"),
  appCode: "main",
  cookies,
  isEmbed: false,
  baseUrl: window.__PODCHASER_BASE_URL__ || "https://api.podchaser.com",
  referrer: document.referrer,
};

if (window.__CLIENT_REQUEST_CONTEXT__) {
  requestContext = {
    ...requestContext,
    ...JSON.parse(window.__CLIENT_REQUEST_CONTEXT__),
  };
}

if (window.__PC_INITIAL_MODULES__) {
  requestContext = {
    ...requestContext,
    loadedModules: window.__PC_INITIAL_MODULES__,
  };
}

window.hydrationDone = false;

Bugsnag.start({
  apiKey: config("bugsnagAPIKey"),
  plugins: [new BugsnagPluginReact()],
});

const history = qhistory(createBrowserHistory(), stringify, parse);

const initialState = mapObjIndexed(
  (value) => fromJS(value),
  JSON.parse(window.__APP_STATE__)
);

async function loadPolyfills() {
  if (typeof window.IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
}

// Create our Redux store.
Promise.all([
  configureStore({
    // Server side rendering would have mounted our state on this global.
    initialState, // eslint-disable-line no-underscore-dangle
    // pass history through for react-router-redux middleware
    history,
    requestContext,
  }),
  loadPolyfills(),
]).then(([store]) => {
  // set req
  window.requestContext = requestContext;

  const token = getStoredToken();

  if (token) {
    store.dispatch(authActions.tokenLoadRequest({ token }));
  } else {
    store.dispatch(authActions.noTokenAtInit());
  }

  /*
   * DISPATCH AUTH ACTIONS
   */
  // before rendering the app, check if we're handling an oauth redirect
  // this needs to happen AFTER dispatching the login actions so the call is made in the context of
  // an already logged in user
  const re = pathToRegexp("/:provider/auth", [
    {
      name: "provider",
      delimiter: "/",
      optional: false,
    },
  ]);

  const matches = re.exec(window.location.pathname);

  if (matches) {
    // special case - set hydrating done to true so we don't try to render the forwarded page
    window.hydrationDone = true;
    const queryString = parse(window.location.search.replace(/^\?/, ""));
    const hashString = parse(window.location.hash.replace(/^#/, ""));

    store.dispatch(
      authActions.resumeOauth({
        provider: matches[1],
        query: queryString,
        hash: hashString,
      })
    );
  }

  /*
   * END DISPATCH AUTH ACTIONS
   */

  if (window.__APHRODITE_CLASSNAMES__) {
    StyleSheet.rehydrate(window.__APHRODITE_CLASSNAMES__);
  }

  store.dispatch({ type: INIT_LOADED_TIMELINES });

  const cache = createEmotionCache();

  let app = null;

  if (requestContext.isEmbed) {
    app = (
      <ReduxProvider store={store}>
        <StoreContext.Provider value={store}>
          <BrowserRouter>
            <HelmetProvider>
              <EmbedApp nonce={config("nonce")} />
            </HelmetProvider>
          </BrowserRouter>
        </StoreContext.Provider>
      </ReduxProvider>
    );
  } else {
    app = (
      <CacheProvider value={cache}>
        <ReduxProvider store={store}>
          <StoreContext.Provider value={store}>
            <BrowserRouter>
              <RequestContext.Provider value={requestContext}>
                {/* Need to pass the nonce in for any inline scripts -
              tried passing it in via a context wrapper but wasn't working consistently.
              Could potentially add it into redux store as well */}
                <HelmetProvider>
                  <AppContainer nonce={config("nonce")} />
                </HelmetProvider>
              </RequestContext.Provider>
            </BrowserRouter>
          </StoreContext.Provider>
        </ReduxProvider>
      </CacheProvider>
    );
  }

  loadableReady(() => {
    const renderMethod = module.hot ? render : hydrate;
    renderMethod(app, document.getElementById("app"));

    window.hydrationDone = true;
  });
});

if (module.hot) {
  module.hot.accept();
}

reportWebVitals();
